import 'react-block-ui/style.css';
import 'react-select-plus/dist/react-select-plus.css';
import 'service/custom_script.js';
import 'service/jquery.validate.js';
import { Icon } from '@salesforce/design-system-react';
import Button from '@salesforce/design-system-react/lib/components/button';
import Combobox from '@salesforce/design-system-react/lib/components/combobox';
import IconSettings from '@salesforce/design-system-react/lib/components/icon-settings';
import Modal from '@salesforce/design-system-react/lib/components/modal';
import ReactGoogleAutocomplete from 'components/admin/externl_component/ReactGoogleAutocomplete';
import jQuery from 'jquery';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    checkItsNotLoggedIn,
    checkLoginWithReturnTrueFalse,
    comboboxFilterAndLimit,
    getApplicantId,
    getMemberId,
    getPermission,
    googleAddressFillOnState,
    handleChange,
    handleDecimalChange,
    postData,
    toastMessageShow,
} from 'service/common.js';
import UserAvatar from './oncallui-react-framework/view/UserAvatar';
import { SLDSISODatePicker, SLDSISODateOfBirthPicker } from '../admin/salesforce/lightning/SLDSISODatePicker';
import { Input } from '@salesforce/design-system-react';


class EditMyDetailsForMemberModal extends Component {
    constructor(props) {
        super(props);
        checkItsNotLoggedIn();

        this.state = {
            loading: false,
            phone: this.props.phone,
            member_address: this.props.address_details,
            member_id: this.props.member_id,
            selectedPlace: '',
            placeAction: false,
            stateList: [],
            unit_number: this.props.unit_number,
            like_selection_ids: [],
            language_selection_ids: [],
            transport_selection_ids: [],
            hours_per_week: '',
            like_options: [],
            like_selection: [],
            language_selection: [],
            language_options: [],
            transport_options: [],
            transport_selection: [],
            status_options: [{ value: "1", label: "Active" }, { value: "0", label: "Inactive" }],
            status: 1,
            applicant_id: getApplicantId(),
            member_id: getMemberId(),
            avatar:'',
            member_details_errors:[],
            is_manual_address: this.props.is_manual_address ? this.props.is_manual_address : false,
            manual_address: ''
        }
        this.permission = (checkLoginWithReturnTrueFalse()) ? ((getPermission() == undefined) ? [] : JSON.parse(getPermission())) : [];

        // we'll use these refs to fix toggling slds datepicker issues
        this.datepickers = {
            date_of_birth: React.createRef(),
        }
    }

    componentDidMount() {
        if (this.state.member_id>0) {
            this.get_member_details(this.props.applicantId);
        }
        this.getStateList();
        this.getReferenceData(getMemberId());
    }
    /**
     * To fetch the list of states
     */
    getStateList() {
        postData('recruitment/Recruitment_member/get_state_list', { member_id: this.state.member_id }).then((result) => {
            if (result.status) {
                this.setState({ stateList: result.data }, () => { });
            } else {
                toastMessageShow('something went wrong', "e");
            }
        });
    }

    //dateError gives us whether given date is valid or not, 0 -> no error, 1 -> error
	//formattedObject gives us Object 
    handleChangeDatePicker = key => (dateYmdHis, e, data, dateError, formattedObject) => {
        let newState = {};
        newState[key] = dateYmdHis;
		this.setState(newState);
		
		//if this function will use for another date, explicitly check key so other date don't change age.
		if(key == 'date_of_birth'){
			if(dateError == 0){
				this.setAgeByDOB(dateYmdHis);
			} else {
				this.setState({age: ''});
			}
		}
		
		let errorClass = (dateError == 1 && formattedObject.formattedDate !='') ? 'slds-has-error' : '';
		newState = {};
		newState[key+'_error'] = errorClass;
        this.setState(newState); 
    }

    handleDatePickerOpened = k => () => {
        Object.keys(this.datepickers).forEach(refKey => {
            const { current } = this.datepickers[refKey] || {}
            if (refKey !== k && current && 'instanceRef' in current) {
                current.instanceRef.setState({ isOpen: false })
            }
        })
    }

    memberSpecific = () => {
        //  jQuery("#update_member").validate({ });
         // this.setState({ validation_calls: true })
          if (!this.state.loading) {
              this.setState({ loading: true });
              var req = {
                  member_id: this.state.member_id, applicant_id: this.props.applicantId, 
                  hours_per_week: this.state.hours_per_week, language_selection: this.state.language_selection,
                  transport_selection: this.state.transport_selection,
                  like_selection: this.state.like_selection,
                  created_by: this.state.updated_by ? this.state.updated_by : this.state.created_by,
                  max_dis_to_travel: this.state.max_dis_to_travel, 
                  mem_experience: this.state.mem_experience
              }
              postData('recruitment/Recruitment_member/update_member', req).then((result) => {
                  if (result.status) {
                      let msg = result.hasOwnProperty('msg') ? result.msg : '';
                      toastMessageShow(result.msg, 's');
                      this.props.closeModal(true);
                  } else {
                      toastMessageShow(result.error, "e");
                  }
                  this.setState({ loading: false });
              });
          }
      }
    /**
     * fetching the reference data of member's object
     */
     getReferenceData = (id) => {
        let req = {};
        if(this.state.applicant_id){
            req = {applicant_id: this.state.applicant_id}
        }else{
            req = {member_id: this.state.member_id}
        }  
        postData("recruitment/Recruitment_member/get_reference_data", req).then((res) => {
            if (res.status) {
                this.setState({
                    like_options: (res.data.likes) ? res.data.likes : [],
                    language_options: (res.data.language) ? res.data.language : [],
                    transport_options: (res.data.language) ? res.data.transport : []
                })
            }
        });       
    }
    /**
     * update the application info 
     * param member_address {state, city, street,address_id, postal} , and phone
     */
    onSubmit = (e) => {
        e.preventDefault();
        jQuery("#create_user").validate({ /* */ });
        this.setState({ validation_calls: true })

        if (!this.state.loading && jQuery("#create_user").valid()) {
            if(this.state.member_id>0){
                this.memberSpecific();
            }
            this.setState({ loading: true });
            let addressData;
            let addressChanges = false;
            let addressDetails = {
                street: this.state.member_address.length>0?this.props.street:''||"",
                suburb:this.state.member_address.length>0?this.props.suburb:''|| "",
                state: this.state.member_address.length>0?this.props.state:''||"",
                postcode:  this.state.member_address.length>0?this.props.postcode:''||""
            };
            
            if (this.state.selectedPlace.address_components) {
                addressData = googleAddressFillOnState(this.state.selectedPlace);
                addressDetails['street'] = addressData.street;
                addressDetails['suburb'] = addressData.city;

                if (this.state.stateList) {
                    this.state.stateList.map((state) => {
                        if (state.name === addressData.state) {
                            addressDetails['state'] = state.id;
                        }
                    }
                    );
                }
                addressDetails['postcode'] = addressData.postal;
                addressChanges = true;
            } else {
                if (this.state.placeAction) {
                    addressChanges = true;
                }
            }
            var req = {
                person_id: this.props.person_id,
                PhoneInput: [{ phone: this.state.phone }],
                address:  addressDetails ,
                unit_number: this.state.unit_number,
                avatar: this.state.avatar,
                is_manual_address: this.state.is_manual_address,
                manual_address: this.state.manual_address,
                date_of_birth: this.state.date_of_birth
            }
            
            postData('sales/Contact/update_contact_for_member_portal', req).then((result) => {
                if (result.status) {
                    toastMessageShow(result.msg, 's');
                    this.props.closeModal(true, 'member')
                } else {
                    toastMessageShow(result.error, "e");
                    this.setState({ loading: false });
                }
            });
        }
    }
    /**
     * fetching the member details if the modal is opened in the edit mode
     */
     get_member_details = (id) => {
        let req = {};
        if(this.state.applicant_id){
            req = {applicant_id: this.state.applicant_id}
        }else{
            req = {member_id: this.state.member_id}
        }  
        postData('recruitment/Recruitment_member/get_applicant_member_details_by_id', req).then((result) => {
            if (result.status) {
                this.setState(result.data);
            } else {
                toastMessageShow(result.error, "e");
            }
        });
    }
    /**
     * Rendering likes, transport and language multi selection boxes
     * @param {str} label 
     * @param {str} stateName - selection state
     * @param {str} valueName - value state
     */
     renderCCComboBox = (id, selection_options, stateName, valueName) => {
        return (
            <Combobox
                id={id}
                predefinedOptionsOnly
                disabled={this.props.disabled}
                events={{
                    onChange: (event, { value }) => {
                        if (this.props.action) {
                            this.props.action('onChange')(event, value);
                        }
                        this.setState({ [valueName]: value });
                    },
                    onRequestRemoveSelectedOption: (event, data) => {
                        this.setState({
                            [valueName]: '',
                            [stateName]: data.selection,
                        });
                    },
                    onSubmit: (event, { value }) => {
                        if (this.props.action) {
                            this.props.action('onChange')(event, value);
                        }
                        this.setState({
                            [valueName]: '',
                            [stateName]: [
                                ...this.state[stateName],
                                {
                                    label: value,
                                    icon: (
                                        <Icon
                                            assistiveText={{ label: 'Account' }}
                                            category="standard"
                                            name="account"
                                        />
                                    ),
                                },
                            ],
                        });
                    },
                    onSelect: (event, data) => {
                        if (this.props.action) {
                            this.props.action('onSelect')(
                                event,
                                ...Object.keys(data).map((key) => data[key])
                            );
                        }
                        this.setState({
                            [valueName]: '',
                            [stateName]: data.selection,
                        });
                    },
                }}

                menuMaxWidth="500px"
                menuItemVisibleLength={5}
                multiple
                options={comboboxFilterAndLimit(
                    this.state[valueName],
                    5,
                    this.state[selection_options],
                    this.state[stateName],
                )}
                selection={this.state[stateName]}
                value={this.state[valueName]}
            />
        );
    }

    componentDidUpdate(props, state) {
        if (!state.avatar && props.avatar) {
            this.setState({avatar: props.avatar});
        }
    }

    render() {
        return (
            <div>
                <IconSettings iconPath={"/assets/salesforce-lightning-design-system/assets/icons"}>
                    <div>
                        <Modal
                            isOpen={this.props.showModal}
                            footer={[
                                <Button disabled={this.state.loading} label="Cancel" onClick={() => this.props.closeModal(false)} />,
                                <Button disabled={this.state.loading} label="Save" disabled={false} variant="brand" onClick={this.onSubmit} />,
                            ]}
                            onRequestClose={this.toggleOpen}
                            heading={this.props.contactId ? "" : "Update Profile"}
                            size="small"
                            className="slds_custom_modal"
                            onRequestClose={() => this.props.closeModal(false)}
                            dismissOnClickOutside={false}
                        >
                            <section className="manage_top" >
                                <div className="container-fluid">
                                    <form id="create_user" autoComplete="off" className="slds_form" style={{ marginLeft: 10, marginRight: 10, marginTop: 40, marginBottom: 40, display: 'block' }}>
                                        <div className="row py-3">
                                            <div className="col-lg-4 col-sm-6 mb-10">
                                                <UserAvatar avatar={this.state.avatar || ""} onUpdateAvatar={data => this.setState({avatar: data})} />
                                            </div>

                                            <div className="col-lg-6 col-sm-6 mb-10">
                                                        <div class="slds-form-element">
                                                            <label className="slds-form-element__label" htmlFor="select-01"><abbr class="slds-required">*</abbr>Date of Birth ('dd/mm/yyyy')</label>
                                                            <div className="slds-form-element__control">
                                                                <div className={[this.state.date_of_birth_error, "SLDS_date_picker_width"].join(' ')}>
                                                                    <SLDSISODateOfBirthPicker
                                                                        ref={this.datepickers.date_of_birth} // !important: this is needed by this custom SLDSISODatePicker
                                                                        className="customer_signed_date"
                                                                        placeholder="Date of Birth"
                                                                        onChange={this.handleChangeDatePicker('date_of_birth')}
                                                                        onOpen={this.handleDatePickerOpened('date_of_birth')}
                                                                        onClear={this.handleChangeDatePicker('date_of_birth')}
                                                                        value={this.state.date_of_birth}
                                                                        input={<Input name="date_of_birth" />}
                                                                        inputProps={{
                                                                            name: "date_of_birth",
                                                                        }}
                                                                        required={true}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>

                                            <div className="col-lg-6 col-sm-6 mb-10">
                                                <div class="slds-form-element">
                                                    <label class="slds-form-element__label" for="text-input-id-1">
                                                        Apartment/Unit number:</label>
                                                    <div class="slds-form-element__control">
                                                        <input maxlength='30'
                                                            class="slds-input"
                                                            type="text"
                                                            name="unit_number"
                                                            placeholder="Unit number"
                                                            value={this.state.unit_number || ''}
                                                            onChange={(e) => handleChange(this, e)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-sm-6  mb-10">
                                                <div class="slds-form-element">
                                                    <label class="slds-form-element__label" for="text-input-id-1">
                                                        Primary Address</label>
                                                    <div className="slds-form-element__control">
                                                        <ReactGoogleAutocomplete className="slds-input add_input mb-1"
                                                            placeholder="street, suburb state postcode, Country"
                                                            // name={"fakeaddress"} 
                                                            autoComplete="off"
                                                            onPlaceSelected={(place) => this.setState({ member_address: place.formatted_address, selectedPlace: place, })}
                                                            types={['address']}
                                                            returntype={'array'}
                                                            value={this.state.is_manual_address == 1 ? '' :  (this.state.member_address || '' )}
                                                            onChange={(evt) => this.setState({ member_address: evt.target.value, selectedPlace: evt.target.value, placeAction: true })}
                                                            onKeyDown={(evt) => this.setState({ member_address: evt.target.value, selectedPlace: evt.target.value, placeAction: true })}
                                                            componentRestrictions={{ country: "au" }}
                                                            disabled={this.state.is_manual_address=='1' ? true : false}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-sm-6 mb-10">
                                                <div class="slds-form-element">
                                                    <div className="slds-form-element__control">
                                                        <div className="slds-checkbox">
                                                            <input type="checkbox" name="is_manual_address" id="is_manual_address"
                                                                onChange={(e) => {
                                                                    handleChange(this, e);
                                                                    if (!this.state.manual_address) {
                                                                        this.setState({ manual_address: '' });
                                                                    }
                                                                }}
                                                                checked={this.state.is_manual_address == "1" ? true : false} />
                                                            <label className="slds-checkbox__label" htmlFor="is_manual_address">
                                                                <span className="slds-checkbox_faux"></span>
                                                                <span className="slds-form-element__label">Address did not show up</span>
                                                            </label>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-sm-6 mb-10">
                                                <div class="slds-form-element">
                                                    <label class="slds-form-element__label" for="text-input-id-1">
                                                        Address (Manual Entry)</label>
                                                    <div class="slds-form-element__control">
                                                        <input 
                                                            class="slds-input"
                                                            type="text"
                                                            name="manual_address"
                                                            placeholder="Manual Address"
                                                            onChange={(e) => handleChange(this, e)}
                                                            value={this.state.is_manual_address == 0 ? '' : (this.state.manual_address==0 ? '' : this.state.manual_address)}
                                                            disabled = {this.state.is_manual_address==0 ? true: false}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-1 col-sm-1 mb-10">
                                                <div class="slds-form-element">
                                                    <label class="slds-form-element__label" for="text-input-id-1">
                                                        <abbr class="slds-required">*</abbr>Phone</label>
                                                    <div class="slds-form-element__control">
                                                        <input type="text"
                                                            class="slds-input"
                                                            name=""
                                                            placeholder="+61"
                                                            value={'+61'}
                                                            disabled={true} 
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-5 col-sm-5 mb-10">
                                                <div class="slds-form-element">
                                                    <label class="slds-form-element__label" for="text-input-id-1">
                                                        <abbr class="slds-required"></abbr></label>
                                                    <div class="slds-form-element__control">
                                                        <input type="text"
                                                            class="slds-input"
                                                            name="phone"
                                                            placeholder="04XXXXXXXX"
                                                            onChange={(e) => handleChange(this, e)}
                                                            value={this.state.phone || ''}
                                                            maxLength="10"
                                                            minLength="10"
                                                            data-rule-phonenumber={true}
                                                            required={true} />
                                                    </div>
                                                </div>
                                            </div>
                                            {this.state.member_id>0&&(<><div className="row py-2">
                                        <div className="col-lg-6 col-sm-6">
                                            <div className="slds-form-element">
                                                <label className="slds-form-element__label" htmlFor="select-01">Transport</label>
                                                <div className="slds-combobox__form-element slds-input-has-icon slds-input-has-icon_right" role="none">
                                                    <IconSettings iconPath="/assets/salesforce-lightning-design-system/assets/icons">
                                                        {this.renderCCComboBox("transports", "transport_options", "transport_selection", "transport_selection_ids")}
                                                    </IconSettings>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-sm-6">
                                            <div className="slds-form-element">
                                                <label className="slds-form-element__label" htmlFor="select-01">Likes</label>
                                                <div className="slds-combobox__form-element slds-input-has-icon slds-input-has-icon_right" role="none">
                                                    <IconSettings iconPath="/assets/salesforce-lightning-design-system/assets/icons">
                                                        {this.renderCCComboBox("likes", "like_options", "like_selection", "like_selection_ids")}
                                                    </IconSettings>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row py-2">


                                        <div className="col-lg-6 col-sm-6">
                                            <div className="slds-form-element">
                                                <label className="slds-form-element__label" htmlFor="select-01">Languages</label>
                                                <div className="slds-combobox__form-element slds-input-has-icon slds-input-has-icon_right" role="none">
                                                    <IconSettings iconPath="/assets/salesforce-lightning-design-system/assets/icons">
                                                        {this.renderCCComboBox("languages", "language_options", "language_selection", "language_selection_ids")}
                                                    </IconSettings>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-sm-6">
                                            <div className="slds-form-element">
                                                <label className="slds-form-element__label" >Experience (In Years) </label>
                                                <div className="slds-form-element__control">
                                                    <input type="text"
                                                        name="mem_experience"
                                                        placeholder="Experience (In Years)"
                                                        onChange={(e) => {
                                                            if (!isNaN(e.target.value)) {
                                                                handleDecimalChange(this, e);
                                                            }
                                                        }}
                                                        maxLength="9"
                                                        value={this.state.mem_experience || ''}
                                                        className="slds-input" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row py-2">
                                        <div className="col-lg-6 col-sm-6">
                                            <div className="slds-form-element">
                                                <label className="slds-form-element__label" htmlFor="select-01">Max distance to travel (in Kms)</label>
                                                <div className="slds-form-element__control" role="none">
                                                    <input type="text"
                                                        name="max_dis_to_travel"
                                                        placeholder="Max distance to travel (in Kms)"
                                                        onChange={(e) => {
                                                            if (!isNaN(e.target.value)) {
                                                                handleDecimalChange(this, e);
                                                            }
                                                        }}
                                                        maxLength="9"
                                                        value={this.state.max_dis_to_travel || ''}
                                                        className="slds-input" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-sm-6">
                                            <div className="slds-form-element">
                                                <label className="slds-form-element__label" >Hours per week</label>
                                                <div className="slds-form-element__control">
                                                    <input type="text"
                                                        name="hours_per_week"
                                                        placeholder="Hours per week"
                                                        onChange={(e) => handleChange(this, e)}
                                                        value={this.state.hours_per_week || ''}
                                                        data-rule-maxlength="6"
                                                        maxLength="6"
                                                        data-msg-number="Please enter valid number"
                                                        className="slds-input" 
                                                        disabled={true} />
                                                </div>
                                            </div>
                                        </div>
                                        </div></>)}
                                        </div>
                                    </form>
                                </div>
                            </section>
                        </Modal>
                    </div>
                </IconSettings>
            </div >
        );
    }
}

export default EditMyDetailsForMemberModal;
